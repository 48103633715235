<template>
    <div>
        <template v-if="listPaymentMethods!=null">
            <template v-if="listPaymentMethods.length>0"  v-for="payment in listPaymentMethods">
                <FormSelfpaidPaymentMethod v-if="renderCompont"
                    v-on:updateDefaultPaymentMethod="updateDefaultPaymentMethod($event)"
                    v-on:updatePaymentMethod="updatePaymentMethod($event)"
                    v-on:addEmptyPaymentMethod="addEmptyPaymentMethod()"
                    v-on:deletePaymentMethod="deletePaymentMethod($event)" :userData="info"
                    :isDefault="payment.is_default" :paymentMethodId="payment.stripe_payment_method_id"
                    :clientId="clientId">
                </FormSelfpaidPaymentMethod>
            </template>
        </template>
    </div>
</template>

<script>

import FormSelfpaidPaymentMethod from '@/@core/components/user-details-selfpaids/SelfpaidPaymentMethod.vue'
import { getMessageError } from "@core/utils/utils";

export default {
    components: {
        FormSelfpaidPaymentMethod,
    },
    props: {
        proclientId: null,
        proPaymentMethods: null,
    },
    data() {
        return {
            info: null,
            renderCompont: true,
            clientId: 0,
            listPaymentMethods:null,
        }
    },
    methods: {
        deletePaymentMethod(paymentId) {
            this.listPaymentMethods = this.listPaymentMethods.filter(x => x.stripe_payment_method_id != paymentId)
            this.renderCompont = false;
            this.$nextTick().then(() => {
                this.renderCompont = true;
            });
            this.$emit("updatePaymentMethodList");
        },
        async updateDefaultPaymentMethod(paymentId) {

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
            });

            let formData = new FormData();
            formData.append("id", this.info.id);
            formData.append("paymentId", paymentId);

            await this.$http
                .post("admin/panel/set_defaulf_payment_selfpay", formData)
                .then((response) => {
                    this.$swal().close();
                    for (let index = 0; index < this.listPaymentMethods.length; index++) {
                        let element = this.listPaymentMethods[index];
                        element.is_default = 0;
                    }

                    let data = this.listPaymentMethods.find(x => x.stripe_payment_method_id == paymentId);
                    if (data) {
                        data.is_default = 1;
                    }

                }).catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                })

        },
        updatePaymentMethod(paymentId) {
            let data = this.listPaymentMethods.find(x => x.stripe_payment_method_id == 0);
            if (data) {
                data.stripe_payment_method_id = paymentId;
                this.$emit("updatePaymentMethodList");
            }
        },
        addEmptyPaymentMethod() {
            let findAdd = this.listPaymentMethods.find(x => x.stripe_payment_method_id == 0);
            if (findAdd == null) {
                let data = JSON.parse('{ "stripe_payment_method_id": 0, "is_default": 0 }');
                this.listPaymentMethods.push(data);
            }
        },
        setDefault(paymentMethodId) {
            let data = this.listPaymentMethods.find(x => x.PaymentMethodId == paymentMethodId);
            data.is_default = 1;
        },
    },
    mounted() {
        if (this.proclientId == null) {
            this.clientId = this.$route.params.client_id
        } else {
            this.clientId = this.proclientId
        }

        if (this.$route.params.item != undefined) {
            this.info = this.$route.params.item;
            if( this.info)
            {
                this.listPaymentMethods =this.info.payment_methods;
            }       
        }
        if (this.proPaymentMethods != null) {
                this.listPaymentMethods = structuredClone(this.proPaymentMethods);
        } 
        //If is edit but has not info return to list
        if (this.listPaymentMethods === undefined || this.listPaymentMethods === null || this.clientId == undefined) {
            this.$router.go(-1)
        } else {
            this.addEmptyPaymentMethod();
        }
    }
}
</script>

<style></style>
